import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import { Images } from '@/constants/Images';
import useBreakpoints from '@/hooks/utility/useBreakpoints';

import Card from '../utility/microcomponents/Card';
import CustomIcons from '../utility/microcomponents/CustomIcons';

export const InsightsDefaultCard = () => {
  const { t } = useTranslation();
  const { breakpointHit } = useBreakpoints();
  const navigate = useNavigate();

  const insightsInstructions = [
    {
      icon: 'budget',
      text: 'Keep track of your fanbase growth with all your social and streaming data in one place',
    },
    {
      icon: 'graph',
      text: 'Compare your performance to similar artists on Spotify, Instagram, TikTok, and YouTube to see how you measure up',
    },
  ];

  return (
    <>
      <Card
        inner
        className={`p0 flex-grow ${breakpointHit ? 'min-h450' : 'min-h450'} pos-rel flex-basis  overflow-hidden cursor-pointer`}
      >
        <div className="d-flex min-h450">
          {!breakpointHit && (
            <div className="placeholder-card-blue overflow-hidden min-h450 flex-basis w100p flex-grow pos-rel">
              <img
                src={breakpointHit ? Images.placeholders.playlistPitchMobile : Images.placeholders.insights}
                alt="tiktok-ads"
                height={'250px'}
                className={`max-w100p ${breakpointHit ? 'ml20' : ''} abs-center-xy object-fit-contain`}
              />
            </div>
          )}
          <div className="flex-grow flex-basis p20 pos-rel">
            <div className={`d-flex ${breakpointHit ? 'flex-wrap' : ''} gap10`}>
              <div>
                <h4>No Spotify Artist profile linked</h4>
                <p className="text-faded">
                  To see your audience data, you need to link your Spotify Artist profile. If you’re a new artist and
                  you haven’t set this up yet,{' '}
                  <a
                    href="http://www.unhurdmusic.com/blog/how-to-claim-your-artist-profiles"
                    rel="noreferrer"
                    target="_blank"
                    className="text-white cursor-pointer"
                  >
                    check out our guide.
                  </a>
                </p>
              </div>
              <Button
                className={`btn-white min-w150 mr0 ${breakpointHit ? 'w100p m0 mt20' : 'mt-auto mb-auto'} ml-auto`}
                onClick={() => {
                  navigate('/profile', { state: { redirect: window.location.pathname } });
                }}
              >
                Link account
              </Button>
            </div>
            <div className={`${breakpointHit ? 'pos-rel mt20' : 'pos-abs b20'} d-flex w100p`}>
              <div className={`${breakpointHit ? '' : 'pr40'} w100p`}>
                {insightsInstructions?.map((item) => (
                  <Card innerInner className="p16 mt8 ml-auto mr-auto d-flex w100p" key={item.text}>
                    <div className="mt-auto mb-auto">
                      <CustomIcons className="material-symbols-outlined text-white" name={item.icon} />
                    </div>
                    <p className="text-left pl16">{t(item.text)}</p>
                  </Card>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={`d-flex pos-rel jc-center gap20 overflow-hidden flex-wrap`}></div>
      </Card>
    </>
  );
};
