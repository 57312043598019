import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@mui/material';

import { Images } from '@/constants/Images';
import useAccountContext from '@/hooks/context/useAccountContext';
import useBreakpoints from '@/hooks/utility/useBreakpoints';

import FacebookLoginButton from '../utility/buttons/FacebookLoginButton';
import SocialAdsButtons from '../utility/buttons/SocialAdsButtons';
import TikTokLoginButton from '../utility/buttons/TikTokLoginButton';
import Card from '../utility/microcomponents/Card';

export const SocialMediaDefaultCard = ({ isMeta, isTikTok }: { isMeta?: boolean; isTikTok?: boolean }) => {
  const { t } = useTranslation();
  const { account } = useAccountContext();
  const { breakpointHit } = useBreakpoints();
  const [isOpenMeta, setIsOpenMeta] = useState<boolean>(false);
  const [isOpenTikTok, setIsOpenTikTok] = useState<boolean>(false);

  const hasTikTokAccount = useMemo(
    () => account?.accessTokens?.filter((item) => item.platform === 'tiktok')[0]?.accessToken,
    [account?.accessTokens]
  );
  const hasMetaAccount = useMemo(
    () => account?.accessTokens?.filter((item) => item.platform === 'meta')[0]?.accessToken,
    [account?.accessTokens]
  );

  return (
    <>
      <SocialAdsButtons
        hideButton
        isOpenMeta={isOpenMeta}
        isOpenTikTok={isOpenTikTok}
        close={() => {
          setIsOpenMeta(false);
          setIsOpenTikTok(false);
        }}
      />

      <Card
        inner
        onClick={() => {
          if (isMeta && hasMetaAccount) {
            setIsOpenMeta(true);
          }
          if (isTikTok && hasTikTokAccount) {
            setIsOpenTikTok(true);
          }
        }}
        className={`p0 flex-grow ${breakpointHit ? 'min-h400 min-w300' : 'min-h450 min-w400'} pos-rel flex-basis placeholder-card-blue overflow-hidden cursor-pointer`}
      >
        <div className={`d-flex pos-rel jc-center gap20 overflow-hidden flex-wrap ${isTikTok ? 'ml40' : ''}`}>
          <img
            src={isMeta ? Images.placeholders.metaAds : Images.placeholders.tiktokAds}
            alt="tiktok-ads"
            height={breakpointHit ? '180px' : '300px'}
            className={`mt20`}
          />
        </div>

        <div className="d-flex card-inner-inner p16 pos-abs b0 w100p bg-blur br-b-12 br-t-0 text-left gap10">
          <div className={`mt-auto mb-auto flex-grow`}>
            {isMeta && <h4>{hasMetaAccount ? t('SOCIAL-ADS.CREATE-A-META-AD') : t('Connect your Meta Ad account')}</h4>}
            {isTikTok && (
              <h4>{hasTikTokAccount ? t('SOCIAL-ADS.CREATE-A-TIKTOK-AD') : t('Connect your TikTok Ad account')}</h4>
            )}

            <p className="text-reduced-opacity mt4">
              {isMeta &&
                (hasMetaAccount
                  ? t('SOCIAL-ADS.META-AD-DESCRIPTION')
                  : t('Get started running ads on Meta to reach new audiences'))}
              {isTikTok &&
                (hasTikTokAccount
                  ? t('SOCIAL-ADS.TIKTOK-AD-DESCRIPTION')
                  : t('Get started running ads on TikTok to reach new audiences'))}
            </p>

            {breakpointHit && isMeta && (
              <>
                {!hasMetaAccount && <FacebookLoginButton isConnect isWide />}
                {hasMetaAccount && (
                  <Button
                    className="btn-white w100p m0 mt20"
                    onClick={() => {
                      setIsOpenMeta(true);
                    }}
                  >
                    <Icon>add</Icon>
                    <span className="pl8">{t('SOCIAL-ADS.NEW-META-AD')}</span>
                  </Button>
                )}
              </>
            )}
            {breakpointHit && isTikTok && (
              <>
                {!hasTikTokAccount && <TikTokLoginButton isConnect isWide />}
                {hasTikTokAccount && (
                  <Button
                    className="btn-white w100p m0 mt20"
                    onClick={() => {
                      setIsOpenTikTok(true);
                    }}
                  >
                    <Icon>add</Icon>
                    <span className="pl8">{t('SOCIAL-ADS.NEW-TIKTOK-AD')}</span>
                  </Button>
                )}
              </>
            )}
          </div>
          {!breakpointHit && isMeta && (
            <>
              {!hasMetaAccount && <FacebookLoginButton isConnect />}
              {hasMetaAccount && (
                <Button
                  className="icon-btn ml-auto btn-grey br50p mt-auto mb-auto"
                  onClick={() => {
                    setIsOpenMeta(true);
                  }}
                >
                  <Icon>add</Icon>
                </Button>
              )}
            </>
          )}
          {!breakpointHit && isTikTok && (
            <>
              {!hasTikTokAccount && <TikTokLoginButton isConnect />}
              {hasTikTokAccount && (
                <Button
                  className="icon-btn ml-auto btn-grey br50p mt-auto mb-auto"
                  onClick={() => {
                    setIsOpenTikTok(true);
                  }}
                >
                  <Icon>add</Icon>
                </Button>
              )}
            </>
          )}
        </div>
      </Card>
    </>
  );
};
