import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@mui/material';

import { Images } from '@/constants/Images';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { PromoteFlowQueryParams } from '@/models/Enums';

import Card from '../utility/microcomponents/Card';
import { BetaTag } from '../utility/statuses/BetaTag';

export const HomeScreenFeatureBanner = ({
  isOne,
  isPlaylisting,
  isBeta,
}: {
  isOne?: boolean;
  isPlaylisting?: boolean;
  isBeta?: boolean;
}) => {
  const { t } = useTranslation();
  const { breakpointHit, breakpointHitCustom } = useBreakpoints(500);
  const [params, setSearchParams] = useSearchParams();

  const handleCardClick = () => {
    if (isPlaylisting) {
      params.set(PromoteFlowQueryParams.PLAYLISTING, 'true');
      setSearchParams(params);
    }
  };

  if (isOne) {
    return (
      <Card
        inner
        className={`p0 flex-grow ${breakpointHit ? 'min-h400' : 'max-h364'} pos-rel flex-basis placeholder-card-blue overflow-hidden cursor-pointer`}
        onClick={handleCardClick}
      >
        <div
          className={`d-flex pos-rel jc-space-evenly gap20 overflow-hidden ${breakpointHit ? 'flex-wrap' : 'pl20 pr20'}`}
        >
          <div
            className={`${breakpointHit ? 'text-center w100p mt-48 p10 order-2' : 'text-left mt-auto mb-auto pr20 max-w600'} ${breakpointHitCustom ? 'pb10' : breakpointHit ? 'pb20' : ''}`}
          >
            {!breakpointHit && (
              <h2>{isPlaylisting ? 'Pitch to playlists' : t('RELEASE-CYCLES.START-A-RELEASE-CYCLE')}</h2>
            )}
            {breakpointHit && (
              <h3 className="mt20">
                {isPlaylisting ? 'Pitch to playlists' : t('RELEASE-CYCLES.START-A-RELEASE-CYCLE')}
              </h3>
            )}
            <p className="mt4">
              {isPlaylisting
                ? 'Pitch your songs to our network of Spotify playlist curators'
                : t('RELEASE-CYCLES.START-A-RELEASE-CYCLE-DESCRIPTION')}
            </p>

            <div
              className={`pos-rel ${breakpointHit ? 'ml-auto mr-auto' : ''}  ${breakpointHitCustom ? 'w100p' : 'w-fit'}`}
            >
              <Button className={`${breakpointHit ? 'mr0 mb0 mt40' : 'mt20'} btn-white ml0 mr0 z-1000 w100p-ml-down`}>
                {isPlaylisting ? 'Pitch now' : t('RELEASE-CYCLES.CREATE-A-NEW-RELEASE')}
              </Button>
              {isBeta && <BetaTag className={`pos-abs ${breakpointHitCustom ? 't10 r30' : 't50 abs-center'}`} />}
            </div>
          </div>
          <img
            src={isPlaylisting ? Images.placeholders.playlistPitchHomeBannerImage : Images.placeholders.releaseCycle}
            alt="tiktok-ads"
            height={breakpointHit ? '220px' : '364px'}
            className={`${breakpointHit ? 'mt-10 min-w0' : 'object-fit-contain min-w50p-or-538'}`}
          />
        </div>
      </Card>
    );
  }

  return (
    <Card
      inner
      className="max-w950 p0 flex-grow max-h400 min-h400 pos-rel flex-basis placeholder-card-blue overflow-hidden flex-basis cursor-pointer"
      onClick={handleCardClick}
    >
      <div className={`d-flex pos-rel jc-center gap20 overflow-hidden flex-wrap`}>
        <div className={`text-center w100p mt-48 p10 order-2`}>
          {
            <h3 className={breakpointHit ? 'mt20 small' : 'mt0'}>
              {isPlaylisting ? 'Pitch to playlists' : t('RELEASE-CYCLES.START-A-RELEASE-CYCLE')}
            </h3>
          }
          <p className="text-reduced-opacity mt4">
            {isPlaylisting
              ? 'Pitch your songs to our network of Spotify playlist curators'
              : t('RELEASE-CYCLES.START-A-RELEASE-CYCLE-DESCRIPTION')}
          </p>
        </div>
        <div className="p10">
          <img
            src={isPlaylisting ? Images.placeholders.playlistPitchHomeBannerImage : Images.placeholders.releaseCycle}
            alt="tiktok-ads"
            height={breakpointHit ? '200px' : '250px'}
            className={`mt0 max-w100p object-fit-contain`}
          />
        </div>
      </div>
      <div className="pos-abs b0 w100p">
        <div className="p10 pos-rel pb20">
          <Button className={`m0 w100p-lg-down btn-white z-1000 abs-center`}>
            {isPlaylisting ? 'Pitch now' : t('RELEASE-CYCLES.CREATE-A-NEW-RELEASE')}
          </Button>
          {isBeta && <BetaTag className={`pos-abs ${breakpointHit ? 't0 r30' : 't40 abs-center'}`} />}
        </div>
      </div>
    </Card>
  );
};
