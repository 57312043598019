import React from 'react';

import { Icon } from '@mui/material';

import { MetaErrorValidationErrorModel } from '@/models/Meta';

import Card from '../utility/microcomponents/Card';

const ErrorBoxCard = ({ errors }: { errors: MetaErrorValidationErrorModel[] }) => {
  return (
    <>
      <Card className="card-error text-left">
        <div className="d-flex gap8 text-error">
          <Icon className="material-symbols-outlined">warning</Icon>
          <h5 className="mt4 text-error">Errors</h5>
        </div>

        {errors &&
          errors[0] &&
          errors?.map((error, index) => (
            <p key={index} className="small mt8">
              {error.errorMessage || (error as unknown as string)}
            </p>
          ))}
        {errors && !errors[0] && <p className="small mt8">Oops...something went wrong, try again or contact us</p>}
      </Card>
    </>
  );
};

export default ErrorBoxCard;
