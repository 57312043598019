import React from 'react';
import { useTranslation } from 'react-i18next';

import useSubscription from '@/hooks/account/useSubscription';
import useBreakpoints from '@/hooks/utility/useBreakpoints';

import FanHubFlowButton from '../utility/buttons/FanHubFlowButton';
import Card from '../utility/microcomponents/Card';
import CustomIcons from '../utility/microcomponents/CustomIcons';
import { ExternalFanHubScreenPreviews } from '../utility/screen-prototypes/ExternalFanHubScreenPreviews';

export const FanHubDefaultCard = () => {
  const { t } = useTranslation();
  const { breakpointHit } = useBreakpoints();
  const { isSubscribed } = useSubscription();

  const playlistInstructions = [
    {
      icon: 'website',
      text: 'Create a central place to send your fans to for updates on your releases and tours',
    },
    {
      icon: 'list',
      text: 'Collect fan emails and phone numbers',
    },
    {
      icon: 'image',
      text: 'Choose a background that matches your vibe to make your Fan Hub your own',
    },
  ];

  return (
    <>
      <Card inner className={`p0 flex-grow pos-rel flex-basis overflow-hidden cursor-pointer`}>
        <div className={`d-flex ${breakpointHit ? '' : 'min-h450'}`}>
          {!breakpointHit && (
            <div className="placeholder-card-blue overflow-hidden flex-basis w100p flex-grow pos-rel">
              <div className="w150p abs-center-xy">
                <ExternalFanHubScreenPreviews isInternal />
              </div>
              {!isSubscribed && (
                <img className="pos-abs r10 t10" src="/images/logos/subscription-pro-lock-logo-light.svg" alt="lock" />
              )}
            </div>
          )}
          <div className="flex-grow flex-basis p20 pos-rel">
            <div className={`d-flex ${breakpointHit ? 'flex-wrap' : ''} gap8`}>
              <div>
                <h4>Create a Fan Hub</h4>
                <p className="text-faded">
                  Combine our design expertise with the power of AI to set up your very own website in minutes.
                </p>
              </div>
              <div className="ml-auto">
                <FanHubFlowButton />
              </div>
            </div>
            <div className={`${breakpointHit ? 'pos-rel mt20' : 'pos-abs b20'} d-flex w100p`}>
              <div className={`${breakpointHit ? '' : 'pr40'} w100p`}>
                {playlistInstructions?.map((item) => (
                  <Card innerInner className="p16 mt8 ml-auto mr-auto d-flex w100p" key={item.text}>
                    <div className="mt-auto mb-auto">
                      <CustomIcons className="material-symbols-outlined text-white" name={item.icon} />
                    </div>
                    <p className="text-left pl16">{t(item.text)}</p>
                  </Card>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};
