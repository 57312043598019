import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button, Icon } from '@mui/material';

import { Images } from '@/constants/Images';
import useArtist from '@/hooks/artist/useArtist';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { PromoteFlowQueryParams } from '@/models/Enums';

import Card from '../utility/microcomponents/Card';
import CustomIcons from '../utility/microcomponents/CustomIcons';
import { HomeScreenFeatureBanner } from './HomeScreenFeatureBanner';

export const PlaylistingDefaultCard = () => {
  const { t } = useTranslation();
  const { breakpointHit } = useBreakpoints();
  const [params, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { artist } = useArtist();

  const playlistInstructions = [
    {
      icon: 'playlistPitch',
      text: 'We’ll recommend high-quality playlists that match your sound',
    },
    {
      icon: 'crowd',
      text: 'We only work with curators we trust, every playlist has real followers and listeners',
    },
    {
      icon: 'check_circle',
      text: 'Our curators will place you on their playlist if it’s a good match. Curators are required to provide feedback if you’re not placed.',
    },
  ];

  return (
    <>
      {artist && breakpointHit && <HomeScreenFeatureBanner isPlaylisting />}
      {artist && !breakpointHit && (
        <Card
          inner
          className={`p0 flex-grow ${breakpointHit ? 'min-h450' : 'min-h450'} pos-rel flex-basis  overflow-hidden cursor-pointer`}
        >
          <div className="d-flex min-h450">
            {!breakpointHit && (
              <div className="placeholder-card-blue overflow-hidden min-h450 flex-basis w100p flex-grow pos-rel">
                <img
                  src={
                    breakpointHit
                      ? Images.placeholders.playlistPitchHomeBannerImage
                      : Images.placeholders.playlistPitchHomeBannerImage
                  }
                  alt="playlisting"
                  height={'250px'}
                  className={`${breakpointHit ? 'ml20' : ''} abs-center-xy`}
                />
              </div>
            )}
            <div className="flex-grow flex-basis p20 pos-rel">
              <div className={`d-flex ${breakpointHit ? 'flex-wrap' : ''} gap10`}>
                <div>
                  <h4>{t('PLAYLISTING.START-A-NEW-PLAYLIST-PITCH')}</h4>
                </div>
                <Button
                  className={`btn-white min-w150 mr0 ${breakpointHit ? 'w100p m0 mt20' : 'mt-auto mb-auto ml-auto'}`}
                  onClick={() => {
                    params.set(PromoteFlowQueryParams.PLAYLISTING, 'true');
                    setSearchParams(params);
                  }}
                >
                  <Icon>add</Icon>
                  <span className="pl8">{t('PLAYLISTING.NEW-PLAYLIST-PITCH')}</span>
                </Button>
              </div>
              <div className={`${breakpointHit ? 'pos-rel mt20' : 'pos-abs b20'} d-flex w100p`}>
                <div className={`${breakpointHit ? '' : 'pr40'} w100p`}>
                  {playlistInstructions?.map((item) => (
                    <Card innerInner className="p16 mt8 ml-auto mr-auto d-flex w100p" key={item.text}>
                      <div className="mt-auto mb-auto">
                        <CustomIcons className="material-symbols-outlined text-white" name={item.icon} />
                      </div>
                      <p className="text-left pl16">{t(item.text)}</p>
                    </Card>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={`d-flex pos-rel jc-center gap20 overflow-hidden flex-wrap`}></div>
        </Card>
      )}
      {!artist && (
        <Card
          inner
          className={`p0 flex-grow ${breakpointHit ? 'min-h450' : 'min-h450'} pos-rel flex-basis  overflow-hidden cursor-pointer`}
        >
          <div className="d-flex min-h450">
            {!breakpointHit && (
              <div className="placeholder-card-blue overflow-hidden min-h450 flex-basis w100p flex-grow pos-rel">
                <img
                  src={
                    breakpointHit
                      ? Images.placeholders.playlistPitchHomeBannerImage
                      : Images.placeholders.playlistPitchHomeBannerImage
                  }
                  alt="tiktok-ads"
                  height={'250px'}
                  className={`${breakpointHit ? 'ml20' : ''} abs-center-xy`}
                />
              </div>
            )}
            <div className="flex-grow flex-basis p20 pos-rel">
              <div className={`d-flex ${breakpointHit ? 'flex-wrap' : ''} gap10`}>
                <div>
                  <h4>No Spotify Artist account linked</h4>
                  <p className="text-faded">
                    To pitch to a playlist, you need to link your Spotify Artist profile. If you’re a new artist and you
                    haven’t set this up yet,{' '}
                    <a
                      href="http://www.unhurdmusic.com/blog/how-to-claim-your-artist-profiles"
                      rel="noreferrer"
                      target="_blank"
                      className="text-white cursor-pointer"
                    >
                      check out our guide.
                    </a>
                  </p>
                </div>
                <Button
                  className={`btn-white min-w115 mr0 ${breakpointHit ? 'w100p m0 mt20' : 'mt-auto mb-auto ml-auto'}`}
                  onClick={() => {
                    navigate('/profile', { state: { redirect: window.location.pathname } });
                  }}
                >
                  Link account
                </Button>
              </div>
              <div className={`${breakpointHit ? 'pos-rel mt20' : 'pos-abs b20'} d-flex w100p`}>
                <div className={`${breakpointHit ? '' : 'pr40'} w100p`}>
                  {playlistInstructions?.map((item) => (
                    <Card innerInner className="p16 mt8 ml-auto mr-auto d-flex w100p" key={item.text}>
                      <div className="mt-auto mb-auto">
                        <CustomIcons className="material-symbols-outlined text-white" name={item.icon} />
                      </div>
                      <p className="text-left pl16">{t(item.text)}</p>
                    </Card>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={`d-flex pos-rel jc-center gap20 overflow-hidden flex-wrap`}></div>
        </Card>
      )}
    </>
  );
};
