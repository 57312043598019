import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Button, Icon } from '@mui/material';

import { Images } from '@/constants/Images';
import useSubscription from '@/hooks/account/useSubscription';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import useUserTracking from '@/hooks/useUserTracking';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { PartnerPerk } from '@/models/PartnerPerks';

import ButtonComponent from '../utility/microcomponents/Button';
import Card from '../utility/microcomponents/Card';
import { PartnerPerkModal } from '../utility/modals/PartnerPerkModal';
import SaveWithProBadge from '../utility/statuses/SaveWithProBadge';

export const PartnerPerkCard = ({ perk, index }: { perk: PartnerPerk; index?: number }) => {
  const { dispatchSnackbar } = useSnackbarContext();
  const { breakpointHit } = useBreakpoints();
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { isSubscribed } = useSubscription();
  const userTracking = useUserTracking();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  if (breakpointHit) {
    return (
      <>
        <PartnerPerkModal
          perk={perk}
          open={isModalOpen}
          close={() => {
            setIsModalOpen(false);
          }}
        />
        <ButtonComponent
          isCustom
          className="p0 w100p mt10 text-left"
          onClick={() => {
            setIsModalOpen(true);
            userTracking?.userOpenedPartnerPerk(perk);
          }}
        >
          <Card key={index} innerInner className="p10 pos-rel w100p">
            <Button
              className="icon-btn pos-abs t0 r0 no-bg m0"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Icon>chevron_right</Icon>
            </Button>
            <h5>{perk.title}</h5>

            <p className="text-faded mt8">{perk.previewTitle}</p>
            <div className="pos-rel mt8">
              <object className="w100p h150 br8 object-fit-cover" data={perk.images.desktop.detail} type="image/png">
                <img src={Images.unhurdFallback} alt={perk.title} className="br8 object-fit-cover w100p h150" />
              </object>
              {perk.saveWithPro && (
                <div className="pos-abs l8 t8">
                  <SaveWithProBadge />
                </div>
              )}
            </div>
          </Card>
        </ButtonComponent>
      </>
    );
  }

  return (
    <Card
      key={index}
      innerInner
      className={`d-flex p10 mt10 pos-rel`}
      style={{ '--animation-number': `${index}` } as React.CSSProperties}
    >
      <object
        className="playlist-image br8 object-fit-cover max-h220"
        data={perk.images.desktop.detail}
        type="image/png"
      >
        <img src={Images.unhurdFallback} alt={perk.title} className="br8 object-fit-cover" />
      </object>
      {perk.saveWithPro && (
        <div className="pos-abs l16 t16">
          <SaveWithProBadge />
        </div>
      )}
      <div className="ml20 pos-rel flex-grow">
        <div className="d-flex jc-space-between">
          <div>
            <h5>{perk.title}</h5>
          </div>
        </div>
        <p className="text-faded mt8">{perk.description}</p>
        <ul className="pl20 pr20 mt4 mb50">
          {perk.body.map((item: string, index: number) => (
            <li key={index} className="text-faded small pt0">
              <p className="text-faded small">{item}</p>
            </li>
          ))}
        </ul>

        <div className="b0 l0 d-flex w100p pos-abs flex-wrap gap8 jc-end">
          {!isSubscribed && perk.saveWithPro && (
            <Button
              className="btn-grey min-w200 m0"
              onClick={() => {
                return setSearchParams('subscription=true');
              }}
            >
              {t('COMMON.UNLOCK-PRO-EXCLUSIVE-DISCOUNT')}
            </Button>
          )}
          <Button
            className="btn-white min-w200 m0"
            onClick={async () => {
              userTracking?.userClickedThroughPartnerPerk(perk);
              if (perk.promotionCode) {
                await navigator.clipboard.writeText(perk.promotionCode);
                await dispatchSnackbar({
                  type: 'OPEN_SNACKBAR',
                  payload: {
                    message: perk.promotionCode + ' ' + t('COMMON.COPIED-TO-CLIPBOARD'),
                    type: 'success',
                  },
                });
                setTimeout(() => {
                  return window.open(perk.button.url);
                }, 1000);
              } else {
                return window.open(perk.button.url);
              }
            }}
          >
            {perk.button.text}
          </Button>
        </div>
      </div>
    </Card>
  );
};
