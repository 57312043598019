import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Chip, CircularProgress, Icon } from '@mui/material';

import useArtist from '@/hooks/artist/useArtist';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import ArtistAPI from '@/network/ArtistAPI';
import { handleApiError } from '@/utility/api';

import Card from '../utility/microcomponents/Card';
import AddGenreModal from '../utility/modals/AddGenreModal';

export const AddYourGenresCard = () => {
  const { t } = useTranslation();
  const { artist, refetchArtist } = useArtist();
  const [isLoadingGenres, setIsLoadingGenres] = useState<boolean>(false);
  const [isGenresModalOpen, setIsGenresModalOpen] = useState<boolean>(false);
  const { dispatchSnackbar } = useSnackbarContext();

  const updateArtistGenres = useCallback(
    async (genres: string[]) => {
      setIsLoadingGenres(true);
      try {
        if (!artist) return;

        await ArtistAPI.updateArtist({ artistId: artist.id, artistDetails: { ...artist.details, genres } });
        await refetchArtist();
      } catch (error: unknown) {
        handleApiError({
          error,
          dispatchSnackbar,
          customMessage: 'Error updating genres, you must have at least one genre selected',
        });
      } finally {
        setIsLoadingGenres(false);
      }
    },
    [artist, dispatchSnackbar, refetchArtist]
  );

  const handleAddGenre = useCallback(
    (genre: string) => {
      if (artist?.details?.genres?.includes(genre)) return;
      updateArtistGenres([...(artist?.details?.genres ?? []), genre]);
    },
    [artist, updateArtistGenres]
  );

  const handleRemoveGenre = useCallback(
    (genre: string) => {
      updateArtistGenres((artist?.details.genres ?? []).filter((value) => value !== genre));
    },
    [artist, updateArtistGenres]
  );

  return (
    <>
      <AddGenreModal
        title={'COMMON.ADD-GENRES'}
        isOpen={isGenresModalOpen}
        onClose={() => setIsGenresModalOpen(false)}
        outputGenre={(genre: string) => {
          handleAddGenre(genre);
          setIsGenresModalOpen(false);
        }}
      />
      <Card inner>
        <h5>{t('COMMON.ADD-YOUR-GENRES')}</h5>
        {!artist?.details.genres ||
          (artist?.details.genres.length === 0 && (
            <p className="small mt8 text-faded">{t('COMMON.NO-GENRES-MESSAGE')}</p>
          ))}
        {!artist && <p className="small mt8 text-faded">{t('COMMON.NO-GENRES-MESSAGE')}</p>}
        <div className="d-flex jc-start gap8 mt8 flex-wrap">
          {artist?.details?.genres?.map((item) => (
            <Chip
              key={item}
              label={<p className="capitalize">{item}</p>}
              className="text-left"
              data-testid={`social-chip-${item}`}
              deleteIcon={<Icon data-testid={`social-chip-${item}-delete`}>cancel</Icon>}
              onDelete={() => handleRemoveGenre(item)}
            />
          ))}
        </div>
        <div className="d-flex">
          <Button
            className="btn-white ml-auto mb0 w100p mr0"
            disabled={isLoadingGenres}
            onClick={() => {
              setIsGenresModalOpen(true);
            }}
          >
            {isLoadingGenres ? (
              <CircularProgress size={16} />
            ) : (
              <span className="d-flex">
                <Icon>add</Icon>
                <span className="pl8 pt1">{t('COMMON.ADD-YOUR-GENRES')}</span>
              </span>
            )}
          </Button>
        </div>
      </Card>
    </>
  );
};
